import { Alert } from '@mui/material';

export const Message = ({ variant, message }) => {
    return (
        <>
            {message && (
                <Alert
                    variant="filled"
                    severity={variant}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 17,
                        py: 1,
                    }}
                >
                    {message}
                </Alert>
            )}
        </>
    );
};
