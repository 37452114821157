import { ReactComponent as IconCommunity } from "../../_assets/icons/wizard/IconComunidade.svg";
import { ReactComponent as IconLearn } from "../../_assets/icons/wizard/iconAprenda.svg";
import { ReactComponent as IconPublicacao } from "../../_assets/icons/wizard/iconPublicacao.svg";
import { ReactComponent as IconPerfil } from "../../_assets/icons/wizard/iconPerfil.svg";

export const menus = [
    {
        title: "Comunidade",
        link: "/comunidade/idCampanhaToReplace",
        accessLevel: 0,
        icon: IconCommunity,
    },
    {
        title: "Postar",
        link: "/novoPost/idCampanhaToReplace",
        accessLevel: 0,
        icon: IconPublicacao,
    },
    {
        title: "Aprenda",
        link: "/aprenda",
        accessLevel: 0,
        icon: IconLearn,
    },
    {
        title: "Perfil",
        link: null,
        accessLevel: 0,
        icon: IconPerfil,
        mobileOnly: true,
    },
];
