import React from 'react';
import { returnEnvironment } from '../../utils';

// import { OmniContent } from '../../components/OmniContent/default';

// Import dinâmico baseado no ambiente
const environment = returnEnvironment();
const OmniContent = require(`../../components/OmniContent/${environment}`).OmniContent;

export const Onboarding = () => {
    return <OmniContent />;
};
