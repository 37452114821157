import { styled } from '@mui/system';
import { Card, InputBase, IconButton } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    backgroundColor: 'var(--grey-50-color)',
    color: 'var(--grey-900-color)',
    overflowY: 'auto',
    marginBottom: '200px',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
        width: '500px',
        maxWidth: '500px'
    }
}));

export const VideoWrapper = styled('div')({
    position: 'relative',
    height: '480px',
    borderRadius: 8,
    marginBottom: '16px',
    '& > div': {
        position: 'relative',
        height: '100% !important',
    },
    '& iframe, & video': {
        borderRadius: 8,
    }
});

export const CommentsContainer = styled('div')({
    padding: 10,
});

export const CommentInput = styled(InputBase)({
    padding: '4px 8px',
    borderRadius: 4,
    marginRight: 8,
    flexGrow: 1,
    border: '1px solid var(--grey-400-color)',
    boxSizing: 'border-box',
    width: '100%',
});
