import React, { useEffect, useState, useCallback } from "react";
import { apiGetLearn } from "../../services";
import { Box, List, ListItem, ListItemButton, ListItemAvatar, ListItemText, Divider, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/ui/Loader";
import { useErrorStore } from "../../store/useErrorStore";

export const Learn = () => {
    // Opcional: Usar ambiente de desenvolvimento
    const showError = useErrorStore((state) => state.showError);

    const [isLoading, setIsLoading] = useState(false);
    const [learnList, setLearnList] = useState([]);

    const openOmniPlayer = (feedId) => {
        loadOmniPlayer(feedId);
    };

    const loadOmniPlayer = useCallback((feedId) => {
        const scriptId = `omni-script-${feedId}`;
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://omnifeed.omnicontent.com.br/omnifeed.js";
            script.dataset.feedId = feedId;
            script.dataset.internalLoad = "true";
            script.dataset.noDisplay = "true";

            if (process.env.REACT_APP_BUILD !== "production") {
                script.dataset.development = "true";
            }

            script.onload = () => window.OmniPlayer.playFeed(feedId);
            document.body.appendChild(script);
        } else {
            window.OmniPlayer?.playFeed(feedId);
        }
    }, []);

    const renderLearbItem = (learn) => (
        <React.Fragment key={learn?.id}>
            <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                <ListItemButton onClick={() => openOmniPlayer(learn.feed)}>
                    <ListItemText primary={learn?.titulo} />
                </ListItemButton>
            </ListItem>
            <Divider />
        </React.Fragment>
    );

    useEffect(() => {
        const fetchLearn = async () => {
            try {
                setIsLoading(true);
                const data = await apiGetLearn();
                setLearnList(data);
            } catch (error) {
                console.log("error", error);

                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchLearn();
    }, []);

    return (
        <Box sx={{ px: 2, mt: 3 }}>
            {isLoading && <Loader />}
            <S.Title className="texteira">Trilha de aprendizado</S.Title>
            {/* <S.Subtitle>Trilhas de aprendizado</S.Subtitle> */}
            <S.Container>
                <List>
                    {learnList.length > 0 ? (
                        learnList.map((learn) => renderLearbItem(learn))
                    ) : (
                        <S.WhithoutVideosText>Sem videos no momento.</S.WhithoutVideosText>
                    )}
                </List>
            </S.Container>
        </Box>
    );
};
