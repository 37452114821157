import React, { useState } from "react";
import { Box, ButtonBase, Drawer, Button } from "@mui/material";
import * as S from "./styles";
import { Opportunity } from "../../opportunities/opportunity";
import { returnEnvironment } from "../../../utils";

export const Opportunities = ({ opportunities = [] }) => {
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const isWizard = returnEnvironment() === "wizard";

    const truncateDescription = (text, isTitle) => {
        const maxLength = isTitle ? 22 : 50;
        if (text?.length <= maxLength) return text;
        return text?.substring(0, maxLength - 3) + "...";
    };

    const openOpportunityModal = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setDrawerOpen(true);
    };

    const closeOpportunityModal = () => {
        setDrawerOpen(false);
        setSelectedOpportunity(null);
    };

    return (
        <>
            <S.Container>
                <S.Title variant="h5">Oportunidades de pontuar</S.Title>
                <S.ScrollContainer>
                    {opportunities?.map((opportunity, index) => (
                        <ButtonBase
                            key={index}
                            onClick={() => openOpportunityModal(opportunity)}
                            sx={{ cursor: "pointer" }}
                        >
                            <S.OpportunityItem isWizard={isWizard} className="opportunityCard">
                                <Box>
                                    <S.BoldText isWizard={isWizard}>
                                        {truncateDescription(opportunity.titulo, true)}
                                    </S.BoldText>
                                    <S.Subtitle
                                        isWizard={isWizard}
                                    >{`+${opportunity.recompensa_valor} pontos`}</S.Subtitle>
                                </Box>
                                <Button
                                    sx={{
                                        backgroundColor: isWizard
                                            ? "var(--primary-light-color)"
                                            : "var(--primary-color)",
                                        color: isWizard ? "var(--primary-color)" : "var(--white-color)",
                                        width: isWizard ? "40%" : "100%",
                                        padding: isWizard ? "3px 8px" : "4px 30px",
                                        textTransform: "none",
                                        fontStyle: isWizard ? "italic" : "normal",
                                    }}
                                    variant="contained"
                                >
                                    Saiba mais
                                </Button>
                            </S.OpportunityItem>
                        </ButtonBase>
                    ))}
                </S.ScrollContainer>
            </S.Container>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={closeOpportunityModal}
                sx={{
                    "& .MuiDrawer-paper": {
                        padding: 2,
                        width: "100%",
                        maxWidth: "600px",
                        height: "100%",
                        backgroundColor: "var(--white-color)",
                        overflowY: "auto",
                    },
                }}
            >
                {selectedOpportunity && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Opportunity opportunity={selectedOpportunity} closeModal={closeOpportunityModal} />
                    </Box>
                )}
            </Drawer>
        </>
    );
};
