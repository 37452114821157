import { CircularProgress, Box } from '@mui/material';

export const Loader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 9999,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
        >
            <CircularProgress />
        </Box>
    );
};
