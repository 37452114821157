import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import {
    Divider,
    Drawer as MuiDrawer,
    styled,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

// Default imports
import logoDefault from "../../../_assets/logo.png";
import logoSimboloDefault from "../../../_assets/logo-simbolo.png";
// Wizard imports
import logoWizard from "../../../_assets/logo_wizard.png";
import logoSimboloWizard from "../../../_assets/logo_wizard.png";

import { useAuthStore } from "../../../store/useAuthStore";
import { ProfileMenu } from "../ProfileMenu";
import { ListItems } from "../ListItems";
import { returnCampainId, returnEnvironment } from "../../../utils";

const drawerWidth = 200;

const Drawer = styled(MuiDrawer)(({ theme, open, environment }) => ({
    width: environment === "wizard" ? drawerWidth : undefined,
    flexShrink: environment === "wizard" ? 0 : undefined,
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: environment === "wizard" ? drawerWidth : open ? drawerWidth : theme.spacing(7),
        height: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        overflowX: "hidden",
        backgroundColor: "var(--primary-dark-color)",
        color: "var(--white-color)",
        ...(environment === "default" && {
            [theme.breakpoints.up("sm")]: {
                width: open ? drawerWidth : theme.spacing(9),
            },
        }),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
        fill: "var(--grey-50-color)",
    },
}));

const UserSection = ({ open, toggleDrawerMobile, environment }) => {
    if (environment === "wizard") {
        return (
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "auto",
                }}
            >
                <ListItemButton
                    onClick={toggleDrawerMobile}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        py: 1.5,
                        width: "100%",
                        color: "var(--grey-300-color)",
                        "&:hover": {
                            backgroundColor: "var(--primary-color)",
                            color: "var(--cattegorychip-color)",
                        },
                    }}
                >
                    <ListItemIcon
                        sx={{
                            color: "inherit",
                            minWidth: "auto",
                            mr: 2,
                            "& svg": {
                                color: "inherit",
                            },
                        }}
                    >
                        <Settings fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Configurações"
                        sx={{
                            color: "inherit",
                            m: 0,
                        }}
                    />
                </ListItemButton>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 50,
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: open ? drawerWidth : drawerWidth / 3,
            }}
        >
            <ProfileMenu openDrawer={open} toggleDrawer={toggleDrawerMobile} />
        </Box>
    );
};

export const DashboardDrawerMenu = ({ open }) => {
    const creator = useAuthStore((state) => state.creator);
    const navigate = useNavigate();
    const location = useLocation();
    const [openDrawerMobile, setOpenDrawerMobile] = React.useState(false);
    const [idCampanha, setIdCampanha] = React.useState("");
    const environment = returnEnvironment();

    React.useEffect(() => {
        const idCampanha = returnCampainId();

        setIdCampanha(idCampanha);
    }, [environment]);

    const toggleDrawerMobile = (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpenDrawerMobile(!openDrawerMobile);
    };

    const handleNavigation = (path) => {
        if (path) {
            const finalPath = environment === "default" && path === "/campanhas" ? `/campanhas/${idCampanha}` : path;
            navigate(finalPath);
        }
    };

    const logo = environment === "wizard" ? logoWizard : logoDefault;
    const logoSimbolo = environment === "wizard" ? logoSimboloWizard : logoSimboloDefault;

    return (
        <>
            <Drawer variant="permanent" open={open} environment={environment}>
                <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                    {open ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2, mt: 1 }}>
                            <img src={logo} alt="logo" style={{ maxWidth: "100%" }} />
                        </Box>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2, mt: 1.5 }}>
                            <img src={logoSimbolo} alt="logoSimbolo" style={{ maxWidth: "100%" }} />
                        </Box>
                    )}
                    <Divider />
                    <List sx={{ pt: 5, flex: 1 }}>
                        <ListItems open={open} />
                    </List>
                    <UserSection open={open} toggleDrawerMobile={toggleDrawerMobile} environment={environment} />
                </Box>
            </Drawer>
            <ProfileMenu openDrawer={openDrawerMobile} toggleDrawer={toggleDrawerMobile} isMobile creator={creator} />
        </>
    );
};
