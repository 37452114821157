import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { Get } from '../../http/httpVerbs';
import { useAuthStore } from '../../store/useAuthStore';
import { Loader } from '../ui/Loader';
import { CampanhaItem } from './CampanhaItem';
import { OmniContent } from '../OmniContent/default';
import { useMobileDetect } from '../../hooks';
import './campanhas.css';
import { IcampanhasItensStatus } from '../../data/Interfaces';
import { useErrorStore } from '../../store/useErrorStore';

const useCampanhas = (creatorId) => {
    const [campanhas, setCampanhas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const showError = useErrorStore((state) => state.showError);

    useEffect(() => {
        const fetchCampanhas = async () => {
            setIsLoading(true);

            try {
                const dataCampanhas = await Get(`${process.env.REACT_APP_API_URL}/campanhas/?creator=${creatorId}`);
                setCampanhas(dataCampanhas);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (creatorId) fetchCampanhas();
    }, [creatorId, showError]);

    return { campanhas, isLoading };
};

export const Campanhas = () => {
    const creator = useAuthStore((state) => state.creator);
    const { campanhas, isLoading } = useCampanhas(creator?.id);
    const isMobile = useMobileDetect();

    const renderCampanhaItem = (campanha, index) => <CampanhaItem key={index} campanha={campanha} creator={creator} />;

    const titles = ['Campanha', 'Cliente', 'Progresso', '', ''];

    if (isLoading) return <Loader />;

    const showTitles = !isMobile && campanhas?.length > 0;

    return (
        <>
            <Container maxWidth="xl" disableGutters sx={{ m: 1 }}>
                {showTitles && (
                    <Box className="headerCampainList">
                        {titles.map((title, index) => (
                            <Box sx={{ flexBasis: '100%' }} key={index}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: 'var(--primary-dark-color)',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                    }}
                                >
                                    {title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                )}
                {campanhas?.length ? campanhas.map(renderCampanhaItem) : <OmniContent />}
            </Container>
        </>
    );
};
