import { Get } from '../http/httpVerbs';

export const apiGetPost = async (id) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/posts/${id}`;
        const response = await Get(url);

        return response;
    } catch (error) {
        console.error('API apiGetPosts ERROR:', error);
        throw error;
    }
};
