import { Get } from '../http/httpVerbs';

export const apiGetCommunity = async (id) => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/comunidades/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};
