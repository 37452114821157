import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Box, Paper } from "@mui/material";

// Default Icons
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useAuthStore } from "../store/useAuthStore";
import firebaseService from "../services/firebaseService";
import { ProfileMenu } from "./ui/ProfileMenu";
import { replaceCampaignIdInMenus } from "../utils/replaceCampaignId";

// Menus
import { menus as defaultMenus } from "../data/default/Menus";
import { menus as wizardMenus } from "../data/wizard/Menus";
import { returnCampainId } from "../utils";

export const DashboardBottomBar = ({ isWizard = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const creator = useAuthStore((state) => state.creator);
    const [value, setValue] = useState(-1);
    const [openDrawerMobile, setOpenDrawerMobile] = useState(false);
    const [menuFeatures, setMenuFeatures] = useState({});
    const [menus, setMenus] = useState(isWizard ? wizardMenus : defaultMenus);
    const [idCampanha, setIdCampanha] = useState("");

    useEffect(() => {
        const fetchCampaignId = async () => {
            const campaignId = returnCampainId();

            setIdCampanha(campaignId);
            const updatedMenus = replaceCampaignIdInMenus(menus, campaignId);
            setMenus(updatedMenus);
        };
        fetchCampaignId();
    }, [isWizard]);

    const handleNavigation = (path) => {
        if (path) {
            navigate(path);
        }
    };

    const toggleDrawerMobile = (event) => {
        if (event?.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpenDrawerMobile(!openDrawerMobile);
    };

    const defaultMenuItems = menus.map((menu) => {
        let icon, activeIcon;
        switch (menu.title) {
            case "Tarefas":
                icon = <CampaignOutlinedIcon />;
                activeIcon = <CampaignIcon />;
                break;
            case "Comunidades":
                icon = <ForumOutlinedIcon />;
                activeIcon = <ForumIcon />;
                break;
            case "FAQ":
                icon = <SchoolOutlinedIcon />;
                activeIcon = <SchoolIcon />;
                break;
            case "Perfil":
                icon = <menu.icon />;
                activeIcon = <AccountCircleIcon />;
                break;
            default:
                icon = <menu.icon />;
                activeIcon = <menu.icon />;
        }

        return {
            label: menu.title,
            icon: icon,
            activeIcon: activeIcon,
            onClick: menu.title === "Perfil" ? toggleDrawerMobile : () => handleNavigation(menu.link),
            path: menu.link,
            mobileOnly: menu.mobileOnly,
        };
    });

    const wizardMenuItems = menus.map((menu) => {
        let icon = <menu.icon />;
        const path = menu.link;

        return {
            label: menu.title,
            icon: icon,
            onClick: menu.title === "Perfil" ? toggleDrawerMobile : () => handleNavigation(path),
            path: menu.link,
            mobileOnly: menu.mobileOnly,
        };
    });

    const menuItems = isWizard ? wizardMenuItems : defaultMenuItems;

    useEffect(() => {
        const loadFeatures = async () => {
            const features = {};
            const menuItems = isWizard ? wizardMenuItems : defaultMenuItems;

            for (const menu of menuItems) {
                if (menu.label === "Perfil") continue;

                const featureName = menu.label.toLowerCase();
                try {
                    const isDisabled = await firebaseService.getFeatureFlag(featureName);
                    if (isDisabled === false) {
                        features[featureName] = false;
                    }
                } catch (error) {
                    console.error(`Error checking feature flag for ${featureName}:`, error);
                }
            }
            setMenuFeatures(features);
        };

        loadFeatures();
    }, [isWizard, idCampanha]);

    useEffect(() => {
        if (location.pathname === "/") {
            setValue(-1);
            return;
        }

        const menuItems = isWizard ? wizardMenuItems : defaultMenuItems;
        const currentIndex = menuItems.findIndex((item) => item.path && location.pathname.startsWith(item.path));
        setValue(currentIndex);
    }, [location, isWizard]);

    return (
        <>
            <Paper
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 2,
                }}
                elevation={3}
            >
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                        menuItems[newValue]?.onClick();
                    }}
                    sx={{
                        backgroundColor: "var(--primary-color)",
                        "& .MuiBottomNavigationAction-root": {
                            color: "var(--white-color)",
                            "&:hover, &.Mui-selected": {
                                "& svg": {
                                    fill: "var(--cattegorychip-color)",
                                    "& path": {
                                        fill: "var(--cattegorychip-color)",
                                    },
                                },
                                "& .MuiBottomNavigationAction-label": {
                                    color: "var(--cattegorychiptext-color)",
                                },
                            },
                        },
                    }}
                >
                    {menuItems
                        .filter((item) => !menuFeatures[item.label.toLowerCase()])
                        .map((item, index) => (
                            <BottomNavigationAction
                                key={index}
                                label={item.label}
                                icon={item.icon}
                                onClick={item.onClick}
                                sx={{
                                    "&:hover, &.Mui-selected": {
                                        "& .MuiBottomNavigationAction-label": {
                                            color: "var(--cattegorychiptext-color)",
                                        },
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: value === index ? "var(--cattegorychip-color)" : "inherit",
                                    },
                                }}
                            />
                        ))}
                </BottomNavigation>
            </Paper>
            <ProfileMenu openDrawer={openDrawerMobile} toggleDrawer={toggleDrawerMobile} creator={creator} />
        </>
    );
};
