import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

const MUTE_PREFERENCE_KEY = 'video-player-mute-preference';

export const VideoPlayer = ({ url, showControls, autoPlay = false, loop = false }) => {
    // Inicializa o estado de mute baseado no localStorage ou no autoPlay
    const [handlePlaying, setHandlePlaying] = useState(autoPlay);
    const [isMuted, setIsMuted] = useState(() => {
        const savedPreference = localStorage.getItem(MUTE_PREFERENCE_KEY);
        // Se não houver preferência salva, usa o valor do autoPlay
        return savedPreference !== null ? savedPreference === 'true' : autoPlay;
    });

    const handlePlay = () => {
        setHandlePlaying(true);
    };

    const toggleMute = (e) => {
        e.stopPropagation();
        const newMuteState = !isMuted;
        setIsMuted(newMuteState);
        localStorage.setItem(MUTE_PREFERENCE_KEY, newMuteState.toString());
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [url]);

    return (
        <div style={{ position: 'relative' }}>
            <ReactPlayer
                url={url}
                controls={showControls}
                playing={handlePlaying}
                onClickPreview={handlePlay}
                width="100%"
                height="100%"
                onPlay={handlePlay}
                muted={isMuted}
                loop={loop}
            />
            <IconButton
                onClick={toggleMute}
                size="small"
                sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    color: 'white',
                    padding: '4px',
                    opacity: 0.7,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        opacity: 1
                    },
                }}
            >
                {isMuted ? 
                    <VolumeOffIcon sx={{ fontSize: 20 }} /> : 
                    <VolumeUpIcon sx={{ fontSize: 20 }} />
                }
            </IconButton>
        </div>
    );
};
