import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const TrophyImage = styled('img')({
    width: 24,
    height: 24,
    marginRight: 12,
});

export const RankingButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '12px 16px',
    backgroundColor: 'var(--primary-light-color)',
    color: 'var(--white-color)',
    borderRadius: 8,
    width: '100%',
    marginBottom: 24,
    textTransform: 'none',
    '&:hover': {
        backgroundColor: 'var(--primary-light-color)',
        opacity: 0.9,
    },
    marginTop: 30,
}));
