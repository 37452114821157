import { Download } from '../http/httpVerbs';

export const apiDownloadFile = async (data) => {
    try {
        const response = await Download(`${process.env.REACT_APP_API_URL}/download`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
