import React, { Suspense, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, Box, Container, Drawer, Fab, IconButton, Tooltip, Typography, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chat, ChevronLeft, ChevronRight, HelpOutline } from "@mui/icons-material";
import DOMPurify from "dompurify";
import { Get } from "../../http/httpVerbs";
import { Loader } from "../ui/Loader";
import { AccordionFL } from "../ui/AccordionFL";
import { CampaignCardsColumn } from "../ui/CampaignCardsColumn";
import { capitalizerFirstLetter } from "../../utils";
import { DescriptionFL } from "../ui/DescriptionFL";
import { TitleFL } from "../ui/TitleFL";
import { ChatModal } from "../ChatModal";
import { Chats } from "../chat/Chats";
import { TextEditor } from "../ui/TextEditor";
import { useAuthStore } from "../../store/useAuthStore";
import { useErrorStore } from "../../store/useErrorStore";
import { useNotificationStore } from "../../store/useNotificationStore";
import { handleClearStorage, handleOpenCampanhaFromNotification } from "../../utils/handleStorage.utils";
import { useMobileDetect } from "../../hooks";

export const Campanha = () => {
    const creator = useAuthStore((state) => state.creator);
    const { t } = useTranslation("translation", { keyPrefix: "campanha" });
    const { id } = useParams();

    const isMobile = useMobileDetect();
    const containerRef = useRef(null);

    const [campanha, setCampanha] = useState({});
    const [campanhaItens, setCampanhaItens] = useState([]);
    const [workflow, setWorkflow] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const campainTitle = campanha?.titulo ? capitalizerFirstLetter(campanha.titulo) : "";
    const [campainNotifications, setCampainNotifications] = useState(0);
    const { notifications, getNotificationsByContext } = useNotificationStore((state) => ({
        notifications: state.notifications,
        getNotificationsByContext: state.getNotificationsByContext,
    }));

    const briefing = decodeURIComponent(campanha?.briefing || "");

    const showError = useErrorStore((state) => state.showError);

    const handleChatButtonClick = () => {
        setChatModalOpen(true);
    };

    const handleCloseChat = () => {
        setChatModalOpen(false);
    };

    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -200, behavior: "smooth" });
        }
    };

    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 200, behavior: "smooth" });
        }
    };

    const renderStage = (stage, index) => {
        const ComponenteEtapa = React.lazy(() => import(`./stages/${stage.etapa}/index`));
        const stageItems = campanhaItens?.filter((cI) => cI.etapa == stage.etapa);

        console.log('Stage:', stage.etapa, 'Items:', stageItems); // Debug

        return (
            <CampaignCardsColumn
                key={stage.id || stage.etapa || index}
                workflow={workflow}
                keyTitle={stage.etapaTitulo}
                t={t}
                isEmpty={!stageItems?.length}
                stage={stage}
            >
                <Suspense fallback={<Loader />}>
                    <ComponenteEtapa
                        campanha={campanha}
                        itens={stageItems}
                        setUpdate={setUpdate}
                    />
                </Suspense>
            </CampaignCardsColumn>
        );
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const dataWorkflows = await Get(`${process.env.REACT_APP_API_URL}/workflows`);

            if (!Array.isArray(dataWorkflows)) {
                setIsLoading(false);
                return;
            }

            if (id && creator) {
                const dataCampanha = await Get(`${process.env.REACT_APP_API_URL}/campanhas/${id}`);
                const dataCampanhaItens = await Get(
                    `${process.env.REACT_APP_API_URL}/campanhasItens?campanha=${id}&creator=${creator.id}`,
                );

                if (dataCampanha) {
                    setCampanha(dataCampanha);
                    const workflowSelected = dataWorkflows.find((wl) => wl.id === dataCampanha.workflow);
                    setWorkflow(workflowSelected);
                }

                if (dataCampanhaItens) {
                    const updatedCampanhaItens = dataCampanhaItens.map((item) => {
                        return { ...item, campanhaBriefing: dataCampanha.briefing };
                    });

                    setCampanhaItens(updatedCampanhaItens);
                }

                setCampainNotifications(getNotificationsByContext("ADMIN_CREATOR_CAMPANHA"));
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const effect = async () => {
            await fetchData();
        };

        effect();

        if (handleOpenCampanhaFromNotification()) {
            handleChatButtonClick();
            handleClearStorage();
        }
    }, [id, creator, update]);

    useEffect(() => {
        setCampainNotifications(getNotificationsByContext("ADMIN_CREATOR_CAMPANHA"));
    }, [notifications]);

    const [activeTab, setActiveTab] = useState("entregas");

    const handleHelpClick = () => {
        setActiveTab("ajuda");
    };

    return (
        <>
            {isLoading && <Loader />}
            <Container
                maxWidth="xl"
                sx={{
                    py: 1,
                    px: 1,
                    boxSizing: "border-box",
                    width: "100%",
                }}
            >
                {/* Header da Campanha */}
                <Box
                    sx={{
                        backgroundColor: "var(--white-color)",
                        borderRadius: "12px",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                        mb: 3,
                        overflow: "hidden",
                        width: "100%",
                        boxSizing: "border-box",
                    }}
                >
                    {/* Banner/Background decorativo */}
                    <Box
                        sx={{
                            height: { xs: "60px", sm: "80px" },
                            background:
                                "linear-gradient(135deg, var(--primary-color) 0%, var(--primary-dark-color) 100%)",
                            position: "relative",
                            display: "flex",
                            alignItems: "flex-end",
                            px: { xs: 2, sm: 3 },
                            pb: { xs: 1, sm: 1 },
                        }}
                    >
                        <TitleFL title={campainTitle} titleSize="body1" color="white" />
                    </Box>
                    {/* Barra de ações/informações */}
                    <Box
                        sx={{
                            px: { xs: 2, sm: 3 },
                            py: 1.5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            gap: 1,
                        }}
                    >
                        {/* Lado esquerdo - Informações */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <Box>
                                <Typography variant="caption" color="text.secondary">
                                    Status
                                </Typography>
                                <Typography variant="body2" fontWeight="500">
                                    Em Andamento
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="caption" color="text.secondary">
                                    Criador
                                </Typography>
                                <Typography variant="body2" fontWeight="500">
                                    {creator.nome}
                                </Typography>
                            </Box>
                        </Box>

                        {/* Lado direito - Ações */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <Tooltip title="Ajuda">
                                <IconButton
                                    size="small"
                                    onClick={handleHelpClick}
                                    sx={{
                                        color: "var(--primary-color)",
                                        "&:hover": { backgroundColor: "rgba(var(--primary-color-rgb), 0.08)" },
                                    }}
                                >
                                    <HelpOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Chat da Campanha">
                                <IconButton
                                    size="small"
                                    onClick={handleChatButtonClick}
                                    sx={{
                                        color: "var(--primary-color)",
                                        "&:hover": { backgroundColor: "rgba(var(--primary-color-rgb), 0.08)" },
                                    }}
                                >
                                    <Badge badgeContent={campainNotifications} color="secondary">
                                        <Chat />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>

                {/* Sistema de Tabs */}
                <Box sx={{ width: "100%" }}>
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            mb: 2,
                        }}
                    >
                        <Tab
                            label="Entregas"
                            value="entregas"
                            sx={{
                                fontWeight: 500,
                                "&.Mui-selected": { color: "var(--primary-color)" },
                            }}
                        />
                        <Tab
                            label="Briefing"
                            value="briefing"
                            sx={{
                                fontWeight: 500,
                                "&.Mui-selected": { color: "var(--primary-color)" },
                            }}
                        />
                        <Tab
                            label="Ajuda"
                            value="ajuda"
                            sx={{
                                fontWeight: 500,
                                "&.Mui-selected": { color: "var(--primary-color)" },
                            }}
                        />
                    </Tabs>

                    {/* Conteúdo das tabs */}
                    {activeTab === "entregas" && (
                        <Box
                            className="containerWorkflow"
                            ref={containerRef}
                            sx={{
                                borderRadius: "8px",
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                overflowX: "auto",
                                overflowY: "visible",
                                WebkitOverflowScrolling: "touch",
                                minHeight: "calc(100vh - 180px)",
                                "&::-webkit-scrollbar": {
                                    height: "8px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "rgba(0, 0, 0, 0.05)",
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "rgba(0, 0, 0, 0.1)",
                                    borderRadius: "4px",
                                },
                                px: 0,
                                py: 0,
                                position: "relative",
                            }}
                        >
                            {workflow?.etapas
                                ?.sort((a, b) => a.id - b.id)
                                ?.map((stage, index) => renderStage(stage, index))}
                        </Box>
                    )}

                    {activeTab === "briefing" && (
                        <Box
                            sx={{
                                p: 3,
                                backgroundColor: "var(--white-color)",
                                borderRadius: "8px",
                                boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                                "& p": { margin: "0.5em 0" },
                                "& ul, & ol": { paddingLeft: "1.5em" },
                                "& a": {
                                    color: "var(--primary-color)",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                },
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(briefing),
                                }}
                            />
                        </Box>
                    )}

                    {activeTab === "ajuda" && (
                        <Box
                            sx={{
                                p: 3,
                                backgroundColor: "var(--white-color)",
                                borderRadius: "8px",
                                boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                                "& h2": {
                                    color: "var(--primary-color)",
                                    fontSize: "1.5rem",
                                    marginBottom: "1rem",
                                    marginTop: "2rem",
                                    "&:first-of-type": {
                                        marginTop: 0,
                                    },
                                },
                                "& p": {
                                    margin: "1rem 0",
                                    lineHeight: "1.6",
                                    color: "text.primary",
                                },
                                "& ul": {
                                    paddingLeft: "1.5rem",
                                    margin: "1rem 0",
                                },
                                "& li": {
                                    margin: "0.5rem 0",
                                    lineHeight: "1.5",
                                },
                            }}
                        >
                            <h2>Como usar a plataforma</h2>
                            <p>
                                Bem-vindo à sua área de campanha! Aqui você encontrará todas as informações e
                                ferramentas necessárias para gerenciar suas entregas de forma eficiente.
                            </p>

                            <h2>Entendendo o Workflow</h2>
                            <p>
                                Seu workflow é organizado em colunas que representam diferentes status. Cada coluna
                                mostra em qual etapa do processo suas entregas estão:
                            </p>
                            <ul>
                                <li>
                                    <strong>Primeira coluna:</strong> Aqui começam todas as suas tarefas
                                </li>
                                <li>
                                    <strong>Colunas intermediárias:</strong> Representam os diferentes estágios do
                                    processo
                                </li>
                                <li>
                                    <strong>Última coluna:</strong> É seu objetivo final - todas as entregas devem
                                    chegar aqui!
                                </li>
                            </ul>

                            <h2>Interagindo com as Entregas</h2>
                            <p>Cada card na tela representa uma entrega específica. Para gerenciar uma entrega:</p>
                            <ul>
                                <li>Clique em qualquer card para ver seus detalhes completos</li>
                                <li>
                                    Dentro do card, você encontrará instruções específicas e poderá interagir com a
                                    entrega
                                </li>
                                <li>Acompanhe o progresso de cada entrega através das colunas</li>
                            </ul>

                            <h2>Seu Objetivo</h2>
                            <p>Sua missão é fazer com que todas as entregas cheguem à coluna final. Para isso:</p>
                            <ul>
                                <li>Siga as instruções específicas de cada entrega</li>
                                <li>Complete todas as etapas necessárias</li>
                                <li>Acompanhe o progresso através das colunas</li>
                                <li>Use o chat caso precise de ajuda em alguma etapa</li>
                            </ul>
                        </Box>
                    )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, mr: 1 }}>
                    <IconButton onClick={handleScrollLeft}>
                        <ChevronLeft />
                    </IconButton>
                    <IconButton onClick={handleScrollRight}>
                        <ChevronRight />
                    </IconButton>
                </Box>
            </Container>

            {/* Chat como Drawer para todos os dispositivos */}
            <Drawer
                anchor="right"
                open={chatModalOpen}
                onClose={() => setChatModalOpen(false)}
                PaperProps={{
                    sx: {
                        width: { xs: "80%", sm: "400px" },
                        backgroundColor: "var(--white-color)",
                        transition: "width 0.3s ease-in-out",
                    },
                }}
                BackdropProps={{
                    sx: {
                        backgroundColor: "rgba(0,0,0,0.3)",
                        backdropFilter: "blur(4px)",
                    },
                }}
            >
                <Box sx={{ height: "100%" }}>
                    <Chats
                        hasHeader
                        headerTitle="Chat da Campanha"
                        title="Mensagens"
                        item={campanha}
                        contexto="ADMIN_CREATOR_CAMPANHA"
                    />
                </Box>
            </Drawer>

            {/* Botão do Chat */}
            <Fab
                color="info"
                aria-label="chat"
                sx={{
                    position: "fixed",
                    bottom: 60,
                    right: 30,
                    backgroundColor: "var(--primary-color)",
                    "&:hover": {
                        backgroundColor: "var(--primary-dark-color)",
                    },
                }}
                onClick={handleChatButtonClick}
            >
                <Badge badgeContent={campainNotifications} color="secondary">
                    <Chat />
                </Badge>
            </Fab>
        </>
    );
};
