import {
    Avatar,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Divider,
    Box,
    CardMedia,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    FormControlLabel,
    useTheme,
    useMediaQuery,
    List,
    ListItem,
} from "@mui/material";
import { FavoriteBorder, ChatBubbleOutline, Favorite, Download, ArrowBack } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { VideoPlayer } from "../../components/ui/VideoPlayer";
import { useParams } from "react-router-dom";
import { apiGetPost, apiPostLike, apiDeleteLike } from "../../services";
import { useAuthStore } from "../../store/useAuthStore";
import { useErrorStore } from "../../store/useErrorStore";
import { Loader } from "../../components/ui/Loader";
import * as S from "./styles";
import { CommentsSheet } from "../../components/commentsSheet";
import { apiPostComment } from "../../services/apiPostComment";
import { apiDownloadFile } from "../../services/apiDownloadFile";
import { useNavigate } from "react-router-dom";
import { LinkCard } from "../../components/post/linkCard";
export const PostDetails = () => {
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(null);
    const [post, setPost] = useState({});
    const [likesCount, setLikesCount] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [videoData, setVideoData] = useState(null);
    const [isCommentsSheetOpen, setIsCommentsSheetOpen] = useState(false);

    const [showModalDownload, setShowModalDownload] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();

    const fetchData = async (likeClick = false) => {
        try {
            if (!likeClick) setIsLoading(true);
            if (id) {
                const data = await apiGetPost(id);
                setPost(data);
                setVideoData(data?.media?.videoUrl);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddLike = async () => {
        try {
            const likeData = { idPost: post?.id, creator: creator?.id };
            await apiPostLike(likeData);
            setLikesCount((prev) => prev + 1);
        } catch (error) {
            console.log("Erro", error);
        }
    };

    const handleRemoveLike = async () => {
        try {
            const likeData = { idPost: post?.id, creator: creator.id };
            await apiDeleteLike(likeData);
            setLikesCount((prev) => prev - 1);
        } catch (error) {
            showError(error);
        }
    };

    const handleLikeToggle = async () => {
        if (isLiked) {
            await handleRemoveLike();
        } else {
            await handleAddLike();
        }
        setIsLiked(!isLiked);
        await fetchData(true);
    };

    const handleDownload = async (videoFile) => {
        try {
            const downloadData = { post: id, creator: creator.id, videoFile };

            const blob = await apiDownloadFile(downloadData);

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${id}.mp4`;

            document.body.appendChild(link);

            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
        }
    };

    const sendComment = async (comment) => {
        try {
            setIsLoading(true);

            const dataComment = {
                campanha: post.campanha,
                texto: comment,
                idPostParent: post.id,
                creator: creator.id,
            };

            await apiPostComment(dataComment);
            await fetchData();
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const effect = async () => {
            await fetchData();
        };
        effect();
    }, [id]);

    useEffect(() => {
        if (post) {
            const likes = post?.engajamento?.likes || [];
            setLikesCount(likes?.length ?? 0);
            setIsLiked(likes?.some((like) => like.creator === creator.id));
        }
    }, [post]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                width: "90%",
                marginTop: 2,
                padding: "0 16px",
                justifyContent: "center",
            }}
        >
            {!isMobile && (
                <Button
                    startIcon={<ArrowBack />}
                    onClick={() => navigate(-1)}
                    sx={{
                        color: "var(--grey-900-color)",
                        textTransform: "none",
                        fontSize: "0.95rem",
                        padding: "8px 16px",
                        minWidth: "auto",
                        borderRadius: "8px",
                        backgroundColor: "var(--grey-50-color)",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                            backgroundColor: "var(--grey-100-color)",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        },
                    }}
                >
                    Voltar
                </Button>
            )}
            <S.StyledCard>
                {isLoading && <Loader />}
                <CardHeader
                    avatar={<Avatar className="creatorAvatar">{post?.creator?.nome?.charAt(0) ?? ""}</Avatar>}
                    title={
                        <Typography variant="subtitle1" fontWeight="bold">
                            {post?.creator?.nome}
                        </Typography>
                    }
                    subheader={post?.createdAt ? new Date(post.createdAt).toLocaleString() : ""}
                />

                <CardContent sx={{ px: 2 }}>
                    {!videoData && (post?.media?.videoCapa ?? post?.media?.imageUrl) && (
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                borderRadius: "16px",
                                overflow: "hidden",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CardMedia
                                component="img"
                                src={post?.media?.videoCapa ?? post?.media?.imageUrl}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                }}
                            />
                        </div>
                    )}

                    {post?.media?.link && (
                        <Box sx={{ width: "100%" }}>
                            <LinkCard title="Compartilhou uma publicação externa:" link={post?.media?.link} />
                        </Box>
                    )}

                    {videoData && (
                        <S.VideoWrapper>
                            <VideoPlayer url={videoData} showControls autoPlay loop />
                        </S.VideoWrapper>
                    )}

                    <Box sx={{ padding: 2 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "pre-line",
                                textTransform: "capitalize",
                                mt: 1,
                            }}
                        >
                            {post?.texto}
                        </Typography>
                    </Box>

                    <Divider sx={{ bgcolor: "var(--grey-400-color)" }} />

                    {post.categorias && post.categorias.length > 0 && (
                        <div
                            style={{
                                marginTop: "25px",
                                display: "flex",
                                gap: "8px",
                                flexWrap: "wrap",
                            }}
                        >
                            {post.categorias.map((categoria, index) => (
                                <Chip
                                    key={index}
                                    label={categoria}
                                    sx={{
                                        bgcolor: "var(--cattegorychip-color)",
                                        color: "var(--cattegorychiptext-color)",
                                    }}
                                />
                            ))}
                        </div>
                    )}

                    <CardActions sx={{ justifyContent: "space-between" }}>
                        <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                            <IconButton onClick={() => setIsCommentsSheetOpen(!isCommentsSheetOpen)}>
                                <ChatBubbleOutline fontSize="medium" />
                                <Typography variant="caption" marginLeft={0.5}>
                                    {String(post?.engajamento?.comentarios?.length ?? 0)}
                                </Typography>
                            </IconButton>
                            <IconButton
                                onClick={handleLikeToggle}
                                sx={{ color: isLiked ? "var(--engagement-color)" : "inherit" }}
                            >
                                {isLiked ? <Favorite fontSize="medium" /> : <FavoriteBorder fontSize="medium" />}
                                <Typography
                                    variant="caption"
                                    marginLeft={0.5}
                                    sx={{ color: "var(--engagement-label)" }}
                                >
                                    {likesCount}
                                </Typography>
                            </IconButton>
                            {videoData && (
                                <IconButton onClick={() => setShowModalDownload(true)}>
                                    <Download fontSize="medium" />
                                </IconButton>
                            )}
                        </div>
                    </CardActions>
                    <S.CommentsContainer>
                        <Typography
                            variant="body1"
                            onClick={() => setIsCommentsSheetOpen(!isCommentsSheetOpen)}
                            style={{ cursor: "pointer", marginTop: 8 }}
                        >
                            {`Ver todos os ${String(post?.engajamento?.comentarios?.length ?? 0)} comentários`}
                        </Typography>
                    </S.CommentsContainer>

                    {isCommentsSheetOpen && (
                        <CommentsSheet
                            isOpen={isCommentsSheetOpen}
                            setIsOpen={setIsCommentsSheetOpen}
                            sendComment={sendComment}
                            comments={post?.engajamento?.comentarios || []}
                        />
                    )}
                </CardContent>
            </S.StyledCard>

            {/* Modal para download */}
            <Dialog open={showModalDownload} onClose={() => setShowModalDownload(false)}>
                <DialogTitle>Leia com atenção:</DialogTitle>
                <DialogContent>
                    <Typography>Você está prestes a realizar o download de um vídeo da nossa comunidade.</Typography>
                    <Typography sx={{ mt: 2 }}>
                        Este conteúdo pode ter sido criado por você ou por outro membro da plataforma. Ao prosseguir com
                        o download, você concorda que:
                    </Typography>
                    <List sx={{ mt: 1, pl: 2 }}>
                        <ListItem sx={{ display: "list-item" }}>
                            <Typography>
                                O uso deste arquivo deve respeitar os Termos de Uso da plataforma, aceitos durante seu
                                cadastro
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <Typography>
                                É proibida a distribuição, comercialização ou uso indevido do conteúdo sem autorização
                                expressa do autor
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <Typography>
                                O descumprimento destas condições pode resultar em suspensão da conta e penalidades
                                cabíveis
                            </Typography>
                        </ListItem>
                    </List>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCheckboxChecked}
                                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                            />
                        }
                        label="Concordo com os termos acima e quero baixar o vídeo"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModalDownload(false)}>Cancelar</Button>
                    <Button
                        onClick={() => {
                            handleDownload(videoData);
                            setShowModalDownload(false);
                        }}
                        disabled={!isCheckboxChecked}
                        variant="contained"
                        color="primary"
                    >
                        Prosseguir com o download
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
