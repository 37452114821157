import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import { Lightbulb } from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

export const menus = [
    {
        title: "Tarefas",
        link: "/campanhas",
        accessLevel: 0,
        icon: DvrOutlinedIcon,
    },
    {
        title: "Comunidades",
        link: "/comunidades",
        accessLevel: 0,
        icon: ForumIcon,
    },
    {
        title: "FAQ",
        link: "/aprenda",
        accessLevel: 0,
        icon: Lightbulb,
    },
    {
        title: "Perfil",
        link: null,
        accessLevel: 0,
        icon: AccountCircleOutlinedIcon,
        mobileOnly: true,
    },
];
