import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

export const TermsModal = ({ open, onClose, title, description }) => {
    return (
        <>
            <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        maxWidth: 600,
                        width: '90%',
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {description}
                    </Typography>
                    <Button
                        onClick={onClose}
                        sx={{ mt: 3, backgroundColor: 'var(--primary-color)' }}
                        variant="contained"
                    >
                        Fechar
                    </Button>
                </Box>
            </Modal>
        </>
    );
};
