import { useInfiniteQuery } from 'react-query';
import { apiGetPosts } from '../services';

export const usePosts = (campanha, filters = {}) => {
    return useInfiniteQuery(
        ['posts', campanha, filters], 
        ({ pageParam = 1 }) => apiGetPosts(pageParam, campanha, filters),
        {
            getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.nextPage : undefined),
        }
    );
};
