import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Avatar, TextField, IconButton, Box, Stack, Typography, Chip, FormControl, Paper, styled } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import TagIcon from "@mui/icons-material/Tag";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthStore } from "../../store/useAuthStore";
import { Post } from "../../http/httpVerbs";
import { Loader } from "../../components/ui/Loader";
import { useErrorStore } from "../../store/useErrorStore";
import { ButtonFL } from "../../components/ui/ButtonFL";
import { apiGetCommunity } from "../../services/apiGetCommunity";
import { CATEGORY_DESAFIO } from "../../utils/constants";

const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const NewPost = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const { state } = location;

    const showError = useErrorStore((state) => state.showError);
    const creator = useAuthStore((state) => state.creator);

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [attachedFile, setAttachedFile] = useState(null);
    const [attachedLink, setAttachedLink] = useState("");
    const [showLinkInput, setShowLinkInput] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [community, setCommunity] = useState([]);

    const [showVideoInput, setShowVideoInput] = useState(false);
    const [showPhotoInput, setShowPhotoInput] = useState(false);
    const [showLinkAnchor, setShowLinkAnchor] = useState(true);

    const [filePreviewUrl, setFilePreviewUrl] = useState(null);

    const fileInputRef = useRef(null);

    const handleInputChange = (e) => setInputValue(e.target.value);

    const handleShowLink = () => {
        setShowLinkInput(!showLinkInput);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) setAttachedFile(file);
    };
    const handleRemoveFile = () => {
        if (filePreviewUrl) {
            URL.revokeObjectURL(filePreviewUrl);
        }
        setAttachedFile(null);
        setFilePreviewUrl(null);
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(categoryId)
                ? prevSelected.filter((id) => id !== categoryId)
                : [...prevSelected, categoryId],
        );
    };

    const handleSubmit = async () => {
        if (showVideoInput && community.tipos.length === 1 && !attachedFile && !attachedLink) {
            alert("Vídeo ou link é obrigatório.");
            return;
        }

        if (!inputValue && !attachedFile && !attachedLink) {
            alert("Por favor, preencha o texto e selecione uma opção (arquivo ou link).");
            return;
        }

        const formData = new FormData();

        formData.append("campanha", Number(id));
        formData.append("texto", inputValue);
        formData.append("creator", creator.id);
        if (state?.oportunidadecreator) {
            formData.append("oportunidadecreator", state.oportunidadecreator);
        }

        const categories = state?.oportunidadecreator ? [...selectedCategories, CATEGORY_DESAFIO] : selectedCategories;

        formData.append("categorias", JSON.stringify(categories));

        if (attachedFile) formData.append("file", attachedFile);
        if (attachedLink) formData.append("link", attachedLink);

        try {
            setIsLoading(true);

            await Post(`${process.env.REACT_APP_API_URL}/posts/`, formData);

            setInputValue("");
            setAttachedFile(null);
            setAttachedLink("");
            setSelectedCategories([]);

            navigate(`/comunidade/${id}`);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            if (id) {
                const dataCommunity = await apiGetCommunity(id);

                setCommunity(dataCommunity);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    }, [id]);

    useEffect(() => {
        if (community?.tipos) {
            setShowVideoInput(community.tipos.includes("VIDEO"));
            setShowPhotoInput(community.tipos.includes("FOTO"));
        }
    }, [community?.tipos]);

    useEffect(() => {
        if (attachedFile && attachedFile.type.startsWith("video/")) {
            const url = URL.createObjectURL(attachedFile);
            setFilePreviewUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [attachedFile]);

    if (isLoading) return <Loader />;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: 4,
                borderRadius: 2,
                backgroundColor: "var(--grey-100-color)",
                color: "var(--grey-800-color)",
                height: "100vh",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <Avatar className="creatorAvatar">{creator.nome ? creator.nome.charAt(0) : "?"}</Avatar>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {creator.nome}
                </Typography>
            </Stack>

            {(showVideoInput || showPhotoInput || showLinkAnchor) && (
                <Typography variant="body2" sx={{ mb: 1 }}>
                    Enviar mídia do seu dispositivo:
                </Typography>
            )}

            <Stack direction="row" spacing={1} mb={1}>
                {(showVideoInput || showPhotoInput) && !attachedFile && (
                    <>
                        <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileUpload} />
                        <IconButton
                            onClick={() => fileInputRef.current.click()}
                            sx={{ color: "var(--grey-800-color)" }}
                        >
                            <ImageIcon />
                        </IconButton>
                    </>
                )}

                {showLinkAnchor && (
                    <IconButton onClick={handleShowLink} sx={{ color: "var(--grey-800-color)" }}>
                        <TagIcon />
                    </IconButton>
                )}
            </Stack>

            {showLinkInput && (
                <TextField
                    placeholder="Insira um link aqui"
                    fullWidth
                    value={attachedLink}
                    onChange={(e) => setAttachedLink(e.target.value)}
                    autoFocus
                    sx={{ marginBottom: 2 }}
                />
            )}

            {attachedFile && (
                <Stack direction="row" alignItems="center" spacing={1} mb={4}>
                    {attachedFile.type.startsWith("video/") ? (
                        <Box
                            sx={{
                                height: "200px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <video
                                controls
                                height="100%"
                                src={filePreviewUrl}
                                style={{
                                    borderRadius: "8px",
                                    objectFit: "contain",
                                }}
                            >
                                Seu navegador não suporta o elemento de vídeo.
                            </video>
                        </Box>
                    ) : (
                        <>
                            <AttachFileIcon />
                            <Typography variant="body2">{attachedFile.name}</Typography>
                        </>
                    )}
                    <IconButton onClick={handleRemoveFile} sx={{ color: "var(--grey-800-color)" }}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            )}
            <TextField
                variant="outlined"
                placeholder="Digite a legenda do seu post"
                fullWidth
                multiline
                rows={3}
                value={inputValue}
                onChange={handleInputChange}
                sx={{
                    backgroundColor: "var(--white-color)",
                    borderRadius: 1,
                    marginBottom: 2,
                }}
            />
            <Typography variant="body2">Selecione uma editoria:</Typography>
            <FormControl fullWidth margin="normal">
                <Paper
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        listStyle: "none",
                        px: 0.5,
                        m: 0,
                        boxShadow: "none",
                        backgroundColor: "var(--grey-100-color)",
                        mb: 10,
                    }}
                    component="ul"
                >
                    {community?.categorias?.map((category) => (
                        <ListItem key={category}>
                            <Chip
                                key={
                                    selectedCategories.includes(category)
                                        ? `selected-${category}`
                                        : `unselected-${category}`
                                }
                                label={category}
                                clickable
                                sx={{
                                    bgcolor: selectedCategories.includes(category)
                                        ? "var(--newpost-cattegorychip-color)"
                                        : "color-mix(in srgb, var(--newpost-cattegorychip-color), white 60%)",
                                    color: selectedCategories.includes(category)
                                        ? "var(--newpost-cattegorychiptext-color)"
                                        : "var(--newpost-cattegorychiptextselected-color)",
                                }}
                                onClick={() => handleCategoryClick(category)}
                                onDelete={
                                    selectedCategories.includes(category) ? () => handleCategoryClick(category) : null
                                }
                            />
                        </ListItem>
                    ))}
                </Paper>
            </FormControl>

            <ButtonFL textKey="Publicar" onClick={handleSubmit} bgcolor="var(--botao-acao)" />
        </Box>
    );
};
