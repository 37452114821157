import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";

export const LinkCard = ({ title, link }) => {
    return (
        <Card
            sx={{
                maxWidth: "100%",
                borderRadius: "8px",
                boxShadow: "none",
                overflow: "hidden",
                border: "1px solid var(--grey-100-color)",
            }}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "16px",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "8px",
                        color: "var(--grey-800-color)",
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: "var(--grey-600-color)",
                        textDecoration: "underline",
                        marginBottom: "16px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                    }}
                >
                    {link}
                </Typography>
                <Box sx={{ width: "100%" }}>
                    <Button
                        variant="contained"
                        fullWidth
                        href={link}
                        target="_blank"
                        sx={{
                            color: "var(--grey-50-color)",
                            textTransform: "none",
                            backgroundColor: "var(--primary-light-color)",
                            "&:hover": {
                                backgroundColor: "var(--grey-100-color)",
                                boxShadow: "none",
                            },
                            boxShadow: "none",
                        }}
                    >
                        Ver o link
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};
