import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useMobileDetect } from "../../hooks";
import "./dashboard.css";
import { Header } from "../../components/ui/Header";
import { Loader } from "../../components/ui/Loader";
import { useAuthStore } from "../../store/useAuthStore";
import { apiGetCreators } from "../../services/apiGetCreators";
import { returnCampainId, returnEnvironment } from "../../utils";
import { DashboardBottomBar } from "../../components/DashboardBottomBar";
import { DashboardDrawerMenu } from "../../components/ui/DashboardDrawerMenu";

// Import dinâmico baseado no ambiente
const environment = returnEnvironment();

export const Dashboard = ({ children }) => {
    const creator = useAuthStore((state) => state.creator);
    const updateCreator = useAuthStore((state) => state.updateCreator);
    const isMobile = useMobileDetect();

    const [LoginComponent, setLoginComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadLoginComponent = async () => {
            const environment = returnEnvironment();
            try {
                const module = await import(`../login/${environment}`);
                setLoginComponent(() => module.Login);
            } catch (error) {
                console.error(`Erro ao carregar o módulo Login para o ambiente ${environment}:`, error);
            }
        };

        loadLoginComponent();
    }, []);

    useEffect(() => {
        const checkHashAndFetchCreator = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const hash = urlParams.get("hash");

            if (hash) {
                try {
                    const decodedData = atob(hash);
                    const [email, validade] = decodedData.split("|");
                    const agora = new Date();

                    if (new Date(validade) > agora) {
                        const response = await apiGetCreators(email);
                        const creatorData = response[0];

                        if (creatorData) {
                            updateCreator(creatorData);

                            urlParams.delete("hash");

                            window.location.href = "/editUserInfo";
                        }
                    }
                } catch (error) {
                    console.log({ error });
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        if (!creator) {
            checkHashAndFetchCreator();
        } else {
            setIsLoading(false);
        }
    }, [creator, updateCreator]);

    if (isLoading || !LoginComponent) {
        return <Loader />;
    }

    if (!creator) {
        const Login = LoginComponent;
        return <Login />;
    }

    return (
        <>
            {isMobile && <Header />}
            <Box className="dash-container">
                {!isMobile && <DashboardDrawerMenu />}

                <Box
                    component="main"
                    sx={{
                        backgroundColor: "var(--grey-100-color)",
                        width: "100%",
                        overflowX: "hidden",
                        flex: 1,
                    }}
                >
                    {children}
                </Box>

                {isMobile && <DashboardBottomBar isWizard={environment === "wizard"} />}
            </Box>
        </>
    );
};
