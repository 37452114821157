import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import rankingIcon from "../../../_assets/images/wizard/ico_ranking.svg";
import * as S from "./styles";

export const RankingCard = ({ community }) => {
    const navigate = useNavigate();
    if (community?.ranking === false) return <></>;

    const handleClick = () => {
        navigate(`/comunidade/${community.id}/ranking`);
    };

    return (
        <S.RankingButton variant="outlined" onClick={handleClick}>
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" gap={1.5}>
                <S.TrophyImage src={rankingIcon} alt="Ícone de Ranking" />
                <Typography
                    letterSpacing={1}
                    sx={{
                        color: "var(--white-color)",
                    }}
                >
                    Ranking da comunidade
                </Typography>
            </Box>
        </S.RankingButton>
    );
};
