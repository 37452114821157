import { Post } from '../http/httpVerbs';

export const apiPostJoinCommunity = async (id, creator) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/comunidades/${id}/creators`, {
            creator: creator,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
