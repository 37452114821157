import React from "react";
import { Box } from "@mui/material";
import { CardHeader } from "../CardHeader";

export const CampaignCardsColumn = ({ workflow, keyTitle, children, t, isEmpty }) => {
    return (
        <Box
            sx={{
                bgcolor: "var(--white-color)",
                borderRadius: 2,
                flexShrink: 0,
                width: { xs: "100%", md: "320px" },
                maxWidth: { xs: "80%", md: "320px" },
                marginRight: 2,
                marginBottom: 2,
                minHeight: { xs: "200px", md: "calc(100vh - 250px)" },
                display: "flex",
                flexDirection: "column",
                transition: "all 0.3s ease",
                boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                },
            }}
        >
            <CardHeader title={keyTitle} />
            <Box sx={{ flex: 1 }}>{children}</Box>
        </Box>
    );
};
