import { Get } from '../http/httpVerbs';

export const apiGetOportunidades = async (params = {}) => {
    try {
        const { campanha, creator, tipo } = params;
        let url = `${process.env.REACT_APP_API_URL}/oportunidades`;

        if (campanha) {
            url += `&campanha=${campanha}`;
        }

        if (creator) {
            url += `&creator=${creator}`;
        }

        if (tipo) {
            url += `&tipo=${tipo}`;
        }

        const response = await Get(url);

        return response;
    } catch (error) {
        console.error('API apiGetOportunidades ERROR:', error);
        throw error;
    }
};
