import { initializeApp } from 'firebase/app';
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';

class FirebaseService {
    constructor() {
        const firebaseConfig = {
            apiKey: 'AIzaSyBruTfPKq9AHHUJsNT3fwsjtyef3DP_l6o',
            authDomain: 'creators-74434.firebaseapp.com',
            projectId: 'creators-74434',
            storageBucket: 'creators-74434.firebasestorage.app',
            messagingSenderId: '434014210487',
            appId: '1:434014210487:web:ab61ceb6e973f621968032',
            measurementId: 'G-HKHS0TLC1L',
        };

        this.app = initializeApp(firebaseConfig);
        this.remoteConfig = getRemoteConfig(this.app);

        this.remoteConfig.settings = {
            minimumFetchIntervalMillis: 0,
        };

        this.initializeConfig();

        this.environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() || 'default';
        this.build = process.env.REACT_APP_BUILD?.toLowerCase() || 'production';
    }

    async initializeConfig() {
        try {
            await fetchAndActivate(this.remoteConfig);
        } catch (error) {
            console.error('Error initializing Remote Config:', error);
        }
    }

    generateFeatureKey(feature) {
        return `${this.environment}_${this.build}_${feature}`;
    }

    async getFeatureFlag(feature) {
        try {
            const flagKey = this.generateFeatureKey(feature);
            const value = getValue(this.remoteConfig, flagKey);

            if (value.asString() === '') {
                return true;
            }

            return value.asBoolean();
        } catch (error) {
            console.error(`Error getting feature flag ${feature}:`, error);
            return true;
        }
    }

    async getFeatureFlagString(feature) {
        try {
            const flagKey = this.generateFeatureKey(feature);
            const value = getValue(this.remoteConfig, flagKey);

            return value.asString();
        } catch (error) {
            console.error(`Error getting feature flag ${feature}:`, error);
            return true;
        }
    }
}

const firebaseService = new FirebaseService();
export default firebaseService;
