import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Select, MenuItem, Box } from '@mui/material';

export const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const languageOptions = [
        { value: 'pt', label: '🇧🇷 Português' },
        { value: 'en', label: '🇺🇸 Ingles' },
        { value: 'es', label: '🇪🇸 Espanhol' },
    ];

    const selectedLanguage = Cookies.get('language');

    const handleChange = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        Cookies.set('language', selectedLanguage, {
            sameSite: 'Strict',
            secure: window.location.protocol === 'https:',
            path: '/',
            expires: 365
        });
    };

    return (
        <Box className="languageee">
            <Select
                id="languageSelect"
                value={selectedLanguage}
                onChange={handleChange}
                size="medium"
                sx={{ fontSize: 13, bgcolor: 'var(--white-color)' }}
            >
                {languageOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value} selected={option.value === selectedLanguage}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};
