import { returnEnvironment } from "../../utils";
import { lazy, Suspense } from "react";

const WizardEditUserInfo = lazy(() => import("./wizard"));
const DefaultEditUserInfo = lazy(() => import("./default"));

export const EditUserInfo = () => {
    const environment = returnEnvironment();
    
    return (
        <Suspense fallback={<div>Carregando...</div>}>
            {environment === "wizard" ? <WizardEditUserInfo /> : <DefaultEditUserInfo />}
        </Suspense>
    );
};

export default EditUserInfo;
