import { Post } from '../http/httpVerbs';

export const apiPostLike = async (likeData) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/likes/`, likeData);
        return response;
    } catch (error) {
        throw error;
    }
};
