import { Post } from '../http/httpVerbs';

export const apiPostOportunidadeCreator = async (data) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/oportunidades/creators`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
