export const defaultValuesForms = (creator) => {
    if (creator) {
        const propertiesToSplit = [
            "instagram_seguidores",
            "tiktok_seguidores",
            "youtube_seguidores",
            "gamer_jogos_genero",
            "gamer_jogos",
        ];

        const modifiedCreator = {
            ...creator,
            ...propertiesToSplit.reduce((acc, prop) => {
                acc[prop] = creator[prop] ? creator[prop]?.split(",") : [];
                return acc;
            }, {}),
        };

        const defaultValues = {
            cliente: creator.cliente || "",
            unidade: creator.unidade || "",
            nome: creator.nome || "",
            apelido: creator.apelido || "",
            genero: creator.genero || "",
            email: creator.email || "",
            telefone: creator.telefone || "",
            datanascimento: creator.datanascimento || "",
            estado: creator.estado || "",
            cidade: creator.cidade || "",
            descricao: creator.descricao || "",
            categorias: creator.categorias || [],
            instagram: creator.instagram || "",
            instagram_seguidores: modifiedCreator.instagram_seguidores || "",
            tiktok: creator.tiktok || "",
            tiktok_seguidores: modifiedCreator.tiktok_seguidores || "",
            youtube: creator.youtube || "",
            youtube_seguidores: modifiedCreator.youtube_seguidores || "",
            youtube_shorts: creator.youtube_shorts || "",
            discord: creator.discord || "",
            gamer: creator.gamer || "",
            gamer_jogos: modifiedCreator.gamer_jogos || [],
            gamer_jogos_outros: creator.gamer_jogos_outros || "",
            gamer_jogos_genero: modifiedCreator.gamer_jogos_genero || [],
            login: creator.email || "",
            senha: creator.senha || "",
            infos_marcas: creator.infos_marcas || "",
            infos_restricoesalimentares: creator.infos_restricoesalimentares || "",
            infos_marcasfavoritas: creator.infos_marcasfavoritas || "",
            infos_pet: creator.infos_pet || "",
            infos_auto: creator.infos_auto || "",
            infos_horasvagas: creator.infos_horasvagas || "",
            infos_filhos: creator.infos_filhos || "",
            infos_viagens: creator.infos_viagens || "",
            info_agente: creator.info_agente || "",
            info_cnpj: creator.info_cnpj || "",
        };

        return defaultValues;
    }
};

export const states = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MG",
    "MS",
    "MT",
    "PA",
    "PB",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RO",
    "RR",
    "RS",
    "SC",
    "SE",
    "SP",
    "TO",
];

export const tiktokFollowersRanges = [
    "Até 10k",
    "Até 50k",
    "Entre 50k-300k",
    "Entre 300k-500k",
    "Acima de 500k",
    "Acima de 1M",
    "Acima de 5M",
];

export const instagramFollowersRanges = [
    "Até 10k",
    "Até 50k",
    "Entre 50k-100k",
    "Entre 100k-300k",
    "Entre 300k-500k",
    "Acima de 500k",
    "Acima de 1M",
];

export const youtubeFollowersRanges = ["Até 50k", "Entre 50k-300k", "Entre 300k-500k", "Acima de 500k", "Acima de 1M"];

export const confirmationOptions = ["Sim", "Não"];

export const genderOptions = [
    "Homem",
    "Mulher",
    "Mulher Trans",
    "Homem Trans",
    "Travesti",
    "Transexual",
    "Queer",
    "Não-binário",
    "Gênero fluido",
    "Transgênero",
    "Prefiro não responder",
    "Outro(s)",
];
