import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useMobileDetect } from '../../../hooks';
import { useLocation } from 'react-router-dom';

export const BackButtonFL = ({ handleGoBack }) => {
    const isMobile = useMobileDetect();

    return (
        <>
            <IconButton onClick={handleGoBack} sx={{ color: isMobile ? 'var(--white-color)' : 'var(--primary-color)' }}>
                <ArrowBackIosNewIcon />
            </IconButton>
        </>
    );
};
