import { Typography, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: 8,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
}));

export const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginBottom: theme.spacing(1),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
    color: 'var(--grey-400-color)',
    marginBottom: theme.spacing(2),
}));

export const JoinButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'var(--grey-900-color)',
    color: 'var(--grey-50-color)',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '&:hover': {
        backgroundColor: 'var(--grey-800-color)',
    },
}));
