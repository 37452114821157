import { Post } from '../http/httpVerbs';

export const apiPostComment = async (commentsData) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/posts/comments/`, commentsData);
        return response;
    } catch (error) {
        throw error;
    }
};
