export { capitalizerFirstLetter } from ".//captalizerFirstLetter.utils";
export { alertColor } from "./alertColors.utils";
export { formatDate } from "./formatDate.utils";
export { removeSpacesFromString } from "./removeSpacesFromString.utils";
export { emailValidator } from "./emailValidator.utils";
export { generateLink } from "./generateLink.utils";
export { getFirstLetterString } from "./getFirstLetterString.utils";
export { getIconByCategory } from "./getIconByCategory.utils";
export { isDisabled } from "./isDisabled.utils";
export { identifyFileType } from "./identifyFileType.utils.js";
export * from "./getStatusColor.utils";
export * from "./forms.utils";
export * from "./isLightOrDark.utils";
export * from "./formsDefaultValues.utils";

export const parseHTMLToText = (encodedHtmlString) => {
    const decodedHtml = decodeURIComponent(encodedHtmlString);

    return <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />;
};

export const returnEnvironment = () => {
    const customizedEnvironments = ["wizard"];
    const environment = process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase();
    return customizedEnvironments.includes(environment) ? environment : "default";
};

export const returnCampainId = () => {
    const environment = returnEnvironment();
    const build = process.env.REACT_APP_BUILD?.toLocaleLowerCase();

    if (environment == "wizard" && build == "develop") return 14;
    else if (environment == "wizard" && build == "production") return 8;
};
