import { Delete } from '../http/httpVerbs';

export const apiDeleteLike = async (likeData) => {
    try {
        const response = await Delete(`${process.env.REACT_APP_API_URL}/likes`, likeData);

        return response;
    } catch (error) {
        throw error;
    }
};
