import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { returnCampainId, returnEnvironment } from "../../utils";
import { useMobileDetect } from "../../hooks";
import firebaseService from "../../services/firebaseService";
import { replaceCampaignIdInMenus } from "../../utils/replaceCampaignId";

const initialMenus = require(`../../data/${returnEnvironment()}/Menus`).menus;

export const ListItems = ({ open }) => {
    const isMobile = useMobileDetect();
    const location = useLocation();
    const navigate = useNavigate();
    const environment = returnEnvironment();
    const [menuFeatures, setMenuFeatures] = useState({});
    const [menus, setMenus] = useState(initialMenus);

    useEffect(() => {
        const fetchCampaignId = async () => {
            const campaignId = returnCampainId();

            const updatedMenus = replaceCampaignIdInMenus(menus, campaignId);
            setMenus(updatedMenus);
        };
        fetchCampaignId();
    }, [environment]);

    useEffect(() => {
        const loadFeatures = async () => {
            const features = {};
            for (const menu of menus) {
                const featureName = menu.title.toLowerCase();
                try {
                    const isDisabled = await firebaseService.getFeatureFlag(featureName);
                    if (isDisabled === false) {
                        features[featureName] = false;
                    }
                } catch (error) {
                    console.error(`Error checking feature flag for ${featureName}:`, error);
                }
            }
            setMenuFeatures(features);
        };

        if (menus.length > 0) {
            loadFeatures();
        }
    }, [menus]);

    const isActive = (link) => {
        return location.pathname.startsWith(link);
    };

    const showLabel = environment === "wizard" || open || isMobile;

    // Filtra os menus baseado no ambiente (mobile/desktop)
    const filteredMenus = menus.filter((menu) => {
        if (isMobile) {
            return true; // Mostra todos os itens no mobile
        }
        return !menu.mobileOnly; // No desktop, esconde os itens mobile-only
    });

    return (
        <List
            component="nav"
            sx={{
                display: "flex",
                flexDirection: { xs: "row", md: "column" },
                color: "var(--white-color)",
                width: "100%",
            }}
        >
            {filteredMenus.map((menu, index) => {
                if (menuFeatures[menu.title.toLowerCase()] === false) {
                    return null;
                }

                const active = isActive(menu.link);
                return (
                    <ListItemButton
                        key={index}
                        onClick={() => navigate(menu.link)}
                        selected={active}
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "flex-start",
                            alignItems: { xs: "center", md: "center" },
                            py: 1.5,
                            "& .MuiListItemIcon-root": {
                                color: "var(--white-color)",
                                "& svg": {
                                    fill: "currentColor",
                                },
                            },
                            "& .MuiListItemText-root .MuiTypography-root": {
                                color: "var(--cattegorychiptext-color)",
                            },
                            "&:hover, &.Mui-selected": {
                                backgroundColor: "var(--primary-color)",
                                "& .MuiListItemIcon-root": {
                                    color: "var(--cattegorychip-color)",
                                },
                                "& .MuiListItemText-root .MuiTypography-root": {
                                    color: "var(--cattegorychiptext-color)",
                                },
                            },
                        }}
                    >
                        {menu.icon && (
                            <ListItemIcon
                                sx={{
                                    color: "inherit",
                                    minWidth: "auto",
                                    mr: showLabel ? 2 : 0,
                                    "& svg": {
                                        color: "inherit",
                                    },
                                }}
                            >
                                <menu.icon />
                            </ListItemIcon>
                        )}
                        {showLabel && (
                            <ListItemText
                                primary={menu.title}
                                sx={{
                                    m: 0,
                                    "& .MuiTypography-root": {
                                        fontSize: { xs: "0.7rem", md: "1rem" },
                                    },
                                }}
                            />
                        )}
                    </ListItemButton>
                );
            })}
        </List>
    );
};
