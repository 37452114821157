import { React, useState, useEffect } from 'react';
import { Toolbar, Grid, Box } from '@mui/material';
import { useAuthStore } from '../../../store/useAuthStore';
import { NotificationMenu } from '../../NotificationMenu';
import { LanguageSelector } from '../LanguageSelector';
import { BackButtonFL } from '../../../components/ui/BackButtonFL';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMobileDetect } from '../../../hooks';
import { returnEnvironment } from '../../../utils';
import LogoOmnicontent from '../../../_assets/logo.png';

import * as S from './styles';

export const Header = () => {
    const [logo, setLogo] = useState(LogoOmnicontent);

    useEffect(() => {
        const environment = returnEnvironment();
        import(`../../../_assets/logo_${environment}.png`)
            .then((module) => {
                setLogo(module.default);
            })
            .catch(() => {
                // Environment sem logo, manter o padrão
                setLogo(LogoOmnicontent);
            });
    }, []);

    const { pathname: currentPath } = useLocation();
    const navigate = useNavigate();
    const creator = useAuthStore((state) => state.creator);
    const isMobile = useMobileDetect();

    const handleGoBack = () => {
        navigate(-1);
    };

    const isHome = currentPath === '/';

    return (
        <S.StyledAppBar position="static">
            <Toolbar>
                {isMobile ? (
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>{!isHome && <BackButtonFL handleGoBack={handleGoBack} />}</Grid>
                        <Grid item>
                            <S.LogoContainer>
                                <S.Logo
                                    className="customLogo" //Apenas para permitir resize em outros temas
                                    src={logo}
                                    alt="logo"
                                />
                            </S.LogoContainer>
                        </Grid>
                        <Grid item>
                            <Box display="flex" alignItems="center">
                                {creator ? <NotificationMenu /> : <LanguageSelector />}
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <BackButtonFL handleGoBack={handleGoBack} />
                        </Grid>
                        <Grid item xs={8}>
                            <S.LogoContainer>
                                <S.Logo src={logo} alt="logo" />
                            </S.LogoContainer>
                        </Grid>
                        <Grid item>
                            <S.MenuContainer>{creator ? <NotificationMenu /> : <LanguageSelector />}</S.MenuContainer>
                        </Grid>
                    </Grid>
                )}
            </Toolbar>
        </S.StyledAppBar>
    );
};
