import i18n, { use as i18nUse } from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import Cookies from 'js-cookie';
import esTranslation from './translations/es.json';
import enTranslation from './translations/en.json';
import ptTranslation from './translations/pt.json';

const resources = {
    es: {
        translation: esTranslation,
    },
    en: {
        translation: enTranslation,
    },
    pt: {
        translation: ptTranslation,
    },
};

if (!Cookies.get('language')) {
    Cookies.set('language', 'pt', {
        sameSite: 'Strict',
        secure: window.location.protocol === 'https:',
        path: '/',
        expires: 365
    });
}

i18nUse(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'pt',
        lng: Cookies.get('language'),
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
